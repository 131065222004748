import styled from "@emotion/styled";
import {colors, sizes} from "patient-ping-remedy/packages/theme";

export const SearchBarDiv = styled.div`
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const StyledTable = styled('table')`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: ${colors.white};

  tr th {
    padding: 0.8rem 1.6rem;
  }
    
  tr td {
    padding: 0.8rem 1.6rem;
  }
    
  td button {
    text-align: left;
  }
    
  tr th:first-of-type, tr td:first-of-type {
    border-left: 1px solid ${colors.gray2};
  }

  tr th:last-of-type, tr td:last-of-type {
    border-right: 1px solid ${colors.gray2};
  }

  td {
    border-bottom: 1px solid ${colors.gray2};
  }
    
  tr th {
    background: ${colors.gray1};
    text-align: left;
    border-top: 1px solid ${colors.gray2};
  }

  /* top-left border-radius */
  tr:first-of-type th:first-of-type {
    border-top-left-radius: 6px;
  }

  /* top-right border-radius */ 
  tr:first-of-type th:last-of-type {
    border-top-right-radius: 6px;
  }

  /* bottom-left border-radius */ 
  tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 6px;
  }

  /* bottom-right border-radius */
  tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 6px;
  }
`;

export const ThButton = styled('th')`
  padding: ${sizes.xSmall};
  cursor: pointer;
`;

export const QuickFilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${sizes.xSmall};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${sizes.small};
`;

export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;
