import Helpers from "./helpers";
import {DataPermission, FeaturePermission, Roster, User, UserRoster} from "../api/dto/dto";

const PermissionsHelpers = {
  // role check for viewing components
  checkForRole(user: User, roleNeeded: string): boolean {
    if (!user) return false;

    if (user.role === roleNeeded) {
      return true;
    }

    if (user.role === Helpers.getAppRoles().USER && roleNeeded === Helpers.getAppRoles().ADMIN) {
      if (this.hasAdminRoleForRoster(user)) {
        return true;
      }
    }

    let hierarchy = Helpers.getAppRoles().hierarchy;

    return hierarchy.indexOf(user.role) > hierarchy.indexOf(roleNeeded);
  },
  isBamboo(user: User): boolean {
    if (!user) return false;
    return user?.role === Helpers.getAppRoles().BAMBOO_ADMIN;
  },
  isSuperAdmin(user: User): boolean {
    if (!user) return false;
    return user?.role === Helpers.getAppRoles().SUPER_ADMIN;
  },
  isAdmin(user: User): boolean {
    if (!user) return false;
    return this.hasAdminRoleForRoster(user) && !this.hasAdminRole(user);
  },
  isSSOUser(user: User | undefined): boolean {
    if (!user) return false;
    return Helpers.isDefined(user.sso_connection);
  },
  hasAdminRole(user: User): boolean {
    return user?.role === Helpers.getAppRoles().SUPER_ADMIN
      || user?.role === Helpers.getAppRoles().BAMBOO_ADMIN;
  },
  checkPermissionDataLevel(userLevel: string, level: string): boolean {
    if (userLevel === level) {
      return true;
    }

    let hierarchy = Helpers.getAppDataRoleDetails().levels.hierarchy;

    return hierarchy.indexOf(userLevel) > hierarchy.indexOf(level);
  },
  checkPermissionFeatureLevel(userLevel: string, level: string): boolean {
    if (userLevel === level) {
      return true;
    }

    let hierarchy = Helpers.getAppFeatureRoleDetails().levels.hierarchy;

    return hierarchy.indexOf(userLevel) > hierarchy.indexOf(level);
  },
  // checks if you have an admin role for any roster
  hasAdminRoleForRoster(user: User): boolean {
    if (!user) return false;

    if (user?.role === Helpers.getAppRoles().SUPER_ADMIN || user?.role === Helpers.getAppRoles().BAMBOO_ADMIN) {
      return true;
    }

    return user.rosters.some((roster: UserRoster) => {
      return roster.data_role?.name.toUpperCase() === Helpers.getAppRoles().ADMIN
        || roster.feature_role?.name.toUpperCase() === Helpers.getAppRoles().ADMIN;
    });
  },
  // checks if you can do a feature action for a specific roster
  canForUserFeatureRoleRoster(user: User, roster: Roster, level: string, feature: string) {
    if(!user || !roster) return;

    if (user.role === Helpers.getAppRoles().BAMBOO_ADMIN) {
      return true;
    }

    let userRoster = user.rosters.find((r: UserRoster) => {
      return r.roster.id === roster.id;
    });

    if (userRoster) {
      let permission = userRoster.feature_role.permissions.find((permission: FeaturePermission) => {
        return permission.feature === feature;
      });

      if (permission) {
        return this.checkPermissionFeatureLevel(permission.level, level);
      }
    }

    return false;
  },

  // checks if you can do a feature action for a specific roster
  canForUserDataRoleRoster(user: User, roster: Roster, level: string, data: string) {
    if(!user || !roster) return;

    if(user.role === Helpers.getAppRoles().BAMBOO_ADMIN){
      return true;
    }

    let userRoster = user.rosters.find((r: UserRoster) => {
      return r.roster.id === roster.id;
    });

    if(userRoster) {
      let permission = userRoster.data_role.permissions.find((permission: DataPermission) => {
        return permission.data_source === data;
      });

      if(permission) {
        return this.checkPermissionDataLevel(permission.level, level);
      }
    }

    return false;
  },
  // checks if you can do an action for any roster
  canForUserFeatureRole(user: User, level: string, feature: string) {
    if (!user) return;

    if (user.role === Helpers.getAppRoles().BAMBOO_ADMIN) {
      return true;
    }

    return user.rosters.some((roster: UserRoster) => {
      let permission = roster.feature_role.permissions.find((permission: FeaturePermission) => {
        return permission.feature === feature;
      });

      if (permission) {
        return this.checkPermissionFeatureLevel(permission.level, level);
      }

      return false;
    });
  },
  canForUserDataRole(user: User, level: string, dataSource: string) {
    if (!user) return;

    // checks if you can do an action for any roster
    if (user.role === Helpers.getAppRoles().BAMBOO_ADMIN) {
      return true;
    }

    return user.rosters.some((roster: UserRoster) => {
      let permission = roster.data_role.permissions.find((permission: DataPermission) => {
        return permission.data_source === dataSource;
      });

      if (permission) {
        return this.checkPermissionDataLevel(permission.level, level);
      }

      return false;
    });
  },
};

export default PermissionsHelpers;
