import styled from '@emotion/styled';
import {colors} from "patient-ping-remedy/packages/theme";
import {css} from "@emotion/react";
import CustomEncounterIcon from "../../../assets/icons/Custom-Icon-Encounter.svg";
import CustomReferralIcon from "../../../assets/icons/Custom-Icon-Referral.svg";
import CustomCrisisIcon from "../../../assets/icons/Custom-Icon-Crisis.svg";
import CustomJIIcon from "../../../assets/icons/Custom-Icon-JI.svg";
import ClinicIcon from "../../../assets/icons/clinic.svg";
import HospitalIcon from "../../../assets/icons/hospital.svg";

const TimelineEventIcon = (icon: string) => css`
    content: '';
    position: absolute;
    background-repeat: no-repeat;
    background-image: url(${icon});
    cursor: pointer;
    background-position: center;
    border-radius: 50%;
    right: -12px;
    top: 0;
    display: block;
    background-size: 32px 32px;
    height: 32px;
    width: 32px;
    z-index: 1;
`;

const TimelineEventLine = (color: string) => css`
    content: '';
    background:
            radial-gradient(farthest-side, ${color} 98%,transparent 100%) bottom right/6px 6px,
            radial-gradient(farthest-side, ${color} 98%,transparent 100%) bottom left /6px 6px,
            linear-gradient(${color},${color}) bottom/calc(100% - 6px) 6px;
    background-repeat: no-repeat;
    position: absolute;
    top: 18px;
    display: block;
    height: 6px;
    width: 100%;
`;

export const VisTimelineContainer = styled.div`
    position: relative;

    .hide-grid {
        .vis-background {
            display: none;
        }
    }

    .show-major-labels {
        .vis-panel.vis-bottom {
            border-top: 4px solid ${colors.gray3};
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }
    }

    .hide-major-labels {
        .vis-panel.vis-bottom {
            border-top: 4px solid ${colors.gray3};
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }
    }

    .vis-timeline {
        padding: 0;
        border: none;
        overflow: visible;
    }

    .year-view {
        .vis-content {
            margin: 0 -32px;
        }
    }

    .vis-active {
        box-shadow: rgba(214, 212, 235, 1) 0 7px 29px 0;
    }

    .vis-group {
        height: calc(100% - 32px);
    }

    .vis-panel.vis-center,
    .vis-panel.vis-bottom {
        border: none;
    }

    div.vis-tooltip {
        font-family: 'Libre Franklin', sans-serif;
        background-color: ${colors.purple1};
        color: ${colors.gray5};
        border: 1px solid ${colors.gray3};
        border-radius: 0;
        padding: 10px 20px;
        text-align: left;
    }

    .vis-text.vis-minor {
        color: ${colors.gray5};
        text-align: center;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    .vis-text.vis-major {
        font-size: 12px;
        font-weight: bold;
        color: ${colors.gray5};
    }

    .vis-text.vis-minor::after {
        content: '';
        display: block;
        background: ${colors.gray4};
        top: 6px;
        left: 50%;
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50%;
    }

    .vis-item {
        background: none;
        border: 0;
        height: 32px;
    }

    .vis-item.vis-selected {
        background: none;
    }

    .vis-ltr {
        .vis-item.encounter {
            &::after {
                ${TimelineEventIcon(CustomEncounterIcon)};
                background-color: ${colors.purple5};
            }

            &::before {
                ${TimelineEventLine(colors.tertiary2)}
            }

            &.inactive {
                &::after {
                    background-color: ${colors.gray4};
                }

                &::before {
                    ${TimelineEventLine(colors.gray4)}
                }
            }
        }

        .vis-item.encounter-hospital {
            &::after {
                ${TimelineEventIcon(HospitalIcon)};
                background-color: ${colors.red5};
                background-position: 75% 50%;
                background-size: 20px 20px;
            }

            &::before {
                ${TimelineEventLine(colors.red4)}
            }

            &.inactive {
                &::after {
                    background-color: ${colors.gray4};
                }

                &::before {
                    ${TimelineEventLine(colors.gray4)}
                }
            }
        }

        .vis-item.encounter-pac {
            &::after {
                ${TimelineEventIcon(ClinicIcon)}
                background-color: ${colors.purple5};
                background-position: 60% 50%;
                background-size: 20px 20px;
            }

            &::before {
                ${TimelineEventLine(colors.tertiary2)}
            }

            &.inactive {
                &::after {
                    background-color: ${colors.gray4};
                }

                &::before {
                    ${TimelineEventLine(colors.gray4)}
                }
            }
        }

        .vis-item.referral {
            &::after {
                ${TimelineEventIcon(CustomReferralIcon)}
                background-color: ${colors.purple5};
            }

            &::before {
                ${TimelineEventLine(colors.tertiary2)}
            }

            &.inactive {
                &::after {
                    background-color: ${colors.gray4};
                }

                &::before {
                    ${TimelineEventLine(colors.gray4)}
                }
            }
        }

        .vis-item.crisis {
            &::after {
                ${TimelineEventIcon(CustomCrisisIcon)}
                background-color: ${colors.red5};
            }

            &::before {
                ${TimelineEventLine(colors.red4)}
            }

            &.inactive {
                &::after {
                    background-color: ${colors.gray4};
                }

                &::before {
                    ${TimelineEventLine(colors.gray4)}
                }
            }
        }

        .vis-item.justice-involved {
            &::after {
                ${TimelineEventIcon(CustomJIIcon)}
                background-color: ${colors.red5};
                background-size: 20px 20px;
            }

            &::before {
                ${TimelineEventLine(colors.red5)}
            }

            &.inactive {
                &::after {
                    background-color: ${colors.gray4};
                }

                &::before {
                    ${TimelineEventLine(colors.gray4)}
                }
            }
        }
    }
`;

export const VisibleYearLabel = styled.div`
    font-size: 12px;
    font-weight: bold;
    color: ${colors.gray5};
    height: 30px;
    display: flex;
    justify-content: end;
    align-items: flex-end;
    position: absolute;
    right: 52px;
    bottom: -20px;
`;

export const VisNavLeft = styled.button((props: {showMajorLabels: boolean}) => (
  css`
    position: absolute;
    left: 0;
    bottom: ${props.showMajorLabels ? '55px' : '35px'};
    background: ${colors.gray3};
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    z-index: 1;
    
    :hover {
        background: ${colors.purple3};
    }
`));

export const VisNavRight = styled.button((props: {showMajorLabels: boolean}) => (
  css`
    position: absolute;
    right: 0;
    bottom: ${props.showMajorLabels ? '55px' : '35px'};
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: ${colors.gray3};
    border: none;
    cursor: pointer;
    z-index: 1;

    :hover {
        background: ${colors.purple3};
    }
`));
