import React, {Fragment, useContext, useEffect} from "react";
import {CarecoApiContext} from "../../app-context/careco-api-context";
import {createFormFieldConfig, useForm} from "../../hooks/useForm";
import {useAlertStore} from "../../store/alert_store";
import {AppSetting, AppSettingDataType} from "../../api/dto/dto";
import {useMutation} from "@tanstack/react-query";
import {ToastType} from "patient-ping-remedy/packages/toast";
import {AxiosError} from "axios";
import {GENERICERROR} from "./clients/manual_encounter/constants/BannerMessages";
import Helpers from "../../helpers/helpers";
import Validate from "../../helpers/validate";
import Wrapper from '../common/pages/Wrapper';
import Header from '../common/pages/Header';
import {SmallTypography} from "patient-ping-remedy/packages/typography";
import Button, {StyleType} from "patient-ping-remedy/packages/button";
import {css} from "@emotion/css";
import DeploymentFooter from "../DeploymentFooter";
import Checkbox from "patient-ping-remedy/packages/checkbox";
import ValidatedInput from "../common/ValidatedInput";

const AppSettingsPage = () => {
  const {carecoApi} = useContext(CarecoApiContext);
  const {isValid, form, setInitialForm, handleChange, handleSubmit} = useForm(submit);
  const {addAlert} = useAlertStore();

  const updateAppSettingsMutation = useMutation({
    mutationFn: async (appSettings: AppSetting[]) => carecoApi?.postAppSettings(appSettings),
    onSuccess: () => {
      addAlert({content: 'App settings updated successfully.', type: ToastType.SUCCESS});
    },
    onError: (error: AxiosError) => {
      const traceId = error.response?.headers['x-trace-id'];
      addAlert({
        content: `Failed to update app settings. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
        type: ToastType.ERROR
      });
      console.error(error);
    },
  });

  function openTogglzConsole() {
    window.open(`${process.env.REACT_APP_CARECO_API_BASE_URL}/togglz_console/index`, '_blank');
  }

  function submit() {
    const updatedAppSettings = Object.values(form).map((setting: any) => setting.value);
    updateAppSettingsMutation.mutate(updatedAppSettings);
  }

  useEffect(() => {
    if (!carecoApi) return;

    carecoApi.getAppSettings()
      .then(appSettings => {
        let formObject : any = {};
        appSettings.forEach(setting => {
          formObject = {
            ...formObject,
            ...createFormFieldConfig(
              setting.name,
              setting,
              {valid: true, message: ''},
              getFormValidateFn(setting),
              true
            )
          };
        });

        setInitialForm(formObject);
      });

    function validateInteger(val: AppSetting, name: string, required: boolean) {
      return Validate.validateInteger(val.value, name, required);
    }

    function  validateRequired(val: AppSetting, name: string, required: boolean) {
      return Validate.required(val.value, name, required);
    }

    function getFormValidateFn(setting: AppSetting) : Function[] {
      if (setting.dataType === AppSettingDataType.INTEGER) {
        return [validateRequired, validateInteger];
      }
      return [validateRequired];
    }
  }, [carecoApi]);

  function renderAppSettings(appSettingName: string) {
    const appSetting = form[appSettingName].value;

    if (appSetting?.dataType === AppSettingDataType.STRING || appSetting?.dataType === AppSettingDataType.INTEGER) {
      return (
        <ValidatedInput
          key={appSettingName}
          id={appSettingName}
          labelText={appSetting?.title}
          required={'required'}
          value={appSetting?.value}
          onChange={(e) => handleChange(e, appSetting.name, {...appSetting, value: e.target.value})}
          validate={() => form[appSettingName]?.valid?.valid ? '' : form[appSettingName]?.valid?.message}
        />
      );
    }

    if (appSetting?.dataType === AppSettingDataType.BOOLEAN) {
      return (
        <Fragment key={appSetting.name}>
          <Checkbox
            key={appSettingName}
            id={appSettingName}
            value={appSetting.value === 'true'}
            onChange={(e) => handleChange(null, appSetting.name, {...appSetting, value: e.toString()})}
            label={appSetting.title}
          />
          <br/><br/>
        </Fragment>
      );
    }
  }

  return (
    <Wrapper>
      <Header>
        App Settings
      </Header>

      <div className={css({display: 'flex', alignItems: 'center', marginBottom: '20px'})}>
        <SmallTypography>
          To dynamically manage application Feature Toggles visit:
        </SmallTypography>
        <Button styleType={StyleType.TERTIARY} onClick={openTogglzConsole}>
          Togglz Console
        </Button>
      </div>

      {
        Object.keys(form).map(setting => renderAppSettings(setting))
      }

      <Button
        className={css({marginTop: '20px'})}
        type="submit"
        onClick={handleSubmit}
        disabled={!isValid()}
      >
        Submit
      </Button>

      <DeploymentFooter />
    </Wrapper>
  );
};

export default AppSettingsPage;
