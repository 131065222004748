import {useEffect, useState} from 'react';
import {cloneDeep} from 'lodash';
import {router} from '../router';
import {useRosterAwareState} from './useRosterAwareState';
import {useRosterStore} from '../store/roster_store';
import {FilterInvalidate} from '../components/common/FilterComponent';
import Helpers from '../helpers/helpers';

export type QueryFnCallback = (forceInvalidate?: boolean) => boolean;

type Options<T> = {
  invalidate: FilterInvalidate;
  params: T;
}

export function useFilterQuery<T extends object>({ invalidate, params }: Options<T>) {
  const { currentRoster } = useRosterStore();
  const currentRosterId = currentRoster?.id ?? 0;

  const [currentRosterParams] = useRosterAwareState(cloneDeep(params));
  const [rosterIdsToInvalidateQueries, setRosterIdsToInvalidateQueries]
    = useState<number[]>([]);

  const previousPathname = router.state.location.pathname;

  useEffect(() => {
    return () => {
      if (previousPathname === router.state.location.pathname) {
        return;
      }

      // setTimeout is required to not invalidate queries right before exiting page,
      // but rather when re-entering the page again
      setTimeout(() => {
        for (const rosterId of rosterIdsToInvalidateQueries) {
          rosterIdRemoveInvalidateQuery(rosterId);
          invalidate(rosterId);
        }
      });
    };
  }, [router.state.location.pathname, rosterIdsToInvalidateQueries]);

  const rosterIdInvalidateQuery = (rosterId: number) => {
    setRosterIdsToInvalidateQueries([...rosterIdsToInvalidateQueries, rosterId]);
  };

  const rosterIdRemoveInvalidateQuery = (rosterId: number) => {
    setRosterIdsToInvalidateQueries(
      [...rosterIdsToInvalidateQueries.filter((id) => id !== rosterId)]
    );
  };

  const queryFnCallback: QueryFnCallback = (forceInvalidate) => {
    const anyFilterIsActive = Object.values(currentRosterParams)
      .some((param) => !Helpers.isEmptyUnknown(param));

    forceInvalidate || anyFilterIsActive
      ? rosterIdInvalidateQuery(currentRosterId)
      : rosterIdRemoveInvalidateQuery(currentRosterId);

    return anyFilterIsActive;
  };

  return {
    params: currentRosterParams,
    queryFnCallback,
  };
}
