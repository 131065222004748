import {useEffect, useRef} from 'react';
import {useIsMounted} from './useIsMounted';
import {useDebounce} from './useDebounce';
import {useRosterAwareState} from './useRosterAwareState';
import {FilterInvalidate} from '../components/common/FilterComponent';
import {QueryFnCallback} from './useFilterQuery';

type Options = {
  invalidate: FilterInvalidate;
  queryFnCallback: QueryFnCallback;
}

export function useSearchQuery({ invalidate, queryFnCallback }: Options) {
  const [searchTerm, setSearchTerm] = useRosterAwareState('');
  const debouncedSearchTerm = useDebounce(searchTerm);

  const isMounted = useIsMounted();
  const anyFilterIsActiveRef = useRef<boolean | null>(null);

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    const anyFilterIsActive = queryFnCallback(!!debouncedSearchTerm);

    if (!anyFilterIsActive) {
      anyFilterIsActiveRef.current = anyFilterIsActive;
      invalidate();
    }
  }, [debouncedSearchTerm]);

  const searchFunction = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const queryFnSearch = () => {
    const anyFilterIsActive = anyFilterIsActiveRef.current ?? queryFnCallback(!!debouncedSearchTerm);
    anyFilterIsActiveRef.current = null;

    if (anyFilterIsActive) {
      setSearchTerm('');
    }

    return !anyFilterIsActive ? debouncedSearchTerm : '';
  };

  return {
    searchTerm,
    searchFunction,
    queryFnSearch,
  };
}
