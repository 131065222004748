import {TableData} from "../components/common/SortableTable";
import {FilterOption} from "../components/common/FilterComponent";
import {Value} from "patient-ping-remedy/packages/dropdown-list/lib/DropdownList";
import {isEqual} from "lodash";

export type FilterItem = {
  id: string;
  value: string;
  label: string;
  group: string;
}

export type FilterConditions = {
  [key: string]: string[];
}

const appRoles = {
  BAMBOO_ADMIN: 'BAMBOO_ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
  USER: 'USER',
  ADMIN: 'ADMIN',
  hierarchy: ['USER', 'ADMIN', 'SUPER_ADMIN', 'BAMBOO_ADMIN']
};

const featurePermissions = {
  CLIENT_LIST: 'CLIENT_LIST',
  CLIENT_PROFILE: 'CLIENT_PROFILE',
  FORMS: 'FORMS',
  LOG: 'LOG',
  NOTIFICATIONS: 'NOTIFICATIONS',
  STATE_REPORTING: 'STATE_REPORTING',
  USER_PERMISSIONS: 'USER_PERMISSIONS',
  USER_PROFILE: 'USER_PROFILE'
};

const featureLevels = {
  VIEW: 'VIEW',
  ACT: 'ACT',
  MANAGE: 'MANAGE',
  hierarchy: ['VIEW', 'ACT', 'MANAGE']
};

const dataPermissions = {
  ADT: 'ADT',
  FINDHELP: 'FINDHELP',
  JI: 'JI',
  OB_CRISIS: 'OB_CRISIS',
  OB_REFERRALS: 'OB_REFERRALS',
  PDMP: 'PDMP',
  ROUNDTRIP: 'ROUNDTRIP'
};


const dataLevels = {
  READ: 'READ',
  WRITE: 'WRITE',
  SSO: 'SSO',
  hierarchy: ['READ', 'SSO', 'WRITE']
};

const Helpers = {
  getAppRoles()  {
    return appRoles;
  },
  getAppDataRoleDetails()  {
    return {
      permissions: dataPermissions,
      levels: dataLevels
    };
  },
  getAppFeatureRoleDetails()  {
    return {
      permissions: featurePermissions,
      levels: featureLevels
    };
  },
  isDefined(value: any) : boolean {
    return !(value === undefined || value === null);
  },
  isEmpty(value: string) : boolean {
    return value === "";
  },
  isEmptyObject(obj: object) : boolean {
    return Object.keys(obj).length === 0;
  },
  isEmptyArray(arr: any[]) : boolean {
    return arr.length === 0;
  },
  isEmptyUnknown(value: unknown) : boolean {
    if (!this.isDefined(value)) {
      return true;
    } else if (Array.isArray(value)) {
      return this.isEmptyArray(value);
    } else if (typeof value === 'object' && value !== null) {
      return this.isEmptyObject(value);
    } else if (typeof value === 'string') {
      return this.isEmpty(value);
    } else {
      return !value;
    }
  },
  calculateTimeDifference(startDate : Date, endDate : Date) {
    const diffTimezone = (startDate.getTimezoneOffset() - endDate.getTimezoneOffset()) * 1000 * 60;
    const diffTime = Math.abs(endDate.getTime() - startDate.getTime() + diffTimezone);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  },
  calculateDaysHoursMinutesTimeDiff(startDate : Date, endDate : Date) {
    let diff = Math.abs(endDate.getTime() - startDate.getTime());
    let days = Math.floor((((diff / 1000) / 60) /60) / 24);
    diff = diff - (days * 1000 * 60 * 60 * 24);
    let hours = Math.floor(((diff / 1000) / 60) / 60);
    diff = diff - (hours * 1000 * 60 * 60);
    let minutes = Math.floor(((diff / 1000) / 60));
    return {
      days, hours, minutes
    };
  },
  calculateSecondsToDaysHrsMinsSecs(seconds: number) {
    let days = Math.floor(seconds / (3600*24));
    let hours = Math.floor(seconds % (3600*24) / 3600);
    let minutes = Math.floor(seconds % 3600 / 60);
    let secs = Math.floor(seconds % 60);
    return {
      days,
      hours,
      minutes,
      secs,
      daysDisplay: days > 0 ? days + (days === 1 ? " day" : " days") : "",
      hoursDisplay: hours > 0 ? hours + (hours === 1 ? " hr" : " hrs") : "",
      minutesDisplay: minutes > 0 ? minutes + " min" : "",
      secondsDisplay: seconds + (seconds === 1 ? " second" : " seconds")
    };
  },
  sortByString(data: TableData[], key: string, sortOrder: string) : any[] {
    return data.sort((a: any, b: any) => {
      if (sortOrder === 'asc') {
        return a[key].value.localeCompare(b[key].value);
      } else {
        return b[key].value.localeCompare(a[key].value);
      }
    });
  },
  sortByNumber(data: TableData[], key: string, sortOrder: string) : any[] {
    return data.sort((a: any, b: any) => {
      if (sortOrder === 'asc') {
        return a[key].value - b[key].value;
      } else {
        return b[key].value - a[key].value;
      }
    });
  },
  sortByDate(data: TableData[], key: string, sortOrder: string) : any[] {
    return data.sort((a: any, b: any) => {
      if (sortOrder === 'asc') {
        return new Date(a[key].value).valueOf() - new Date(b[key].value).valueOf();
      } else {
        return new Date(b[key].value).valueOf() - new Date(a[key].value).valueOf();
      }
    });
  },
  sort(data: TableData[], type: 'string' | 'number' | 'date' | 'array' | 'custom', key: string, sortOrder: string) : any[] {
    if(type === 'number') {
      return this.sortByNumber(data, key, sortOrder);
    } else if(type === 'date') {
      return this.sortByDate(data, key, sortOrder);
    } else {
      return this.sortByString(data, key, sortOrder);
    }
  },
  search(searchTerm: string, data: any[], searchKeys: string[]) : any[] {
    return data.filter((item: any) => {
      return searchKeys.some((key: string) => {
        return item[key].value.toString().toLowerCase().includes(searchTerm.toLowerCase());
      });
    });
  },
  filter(filters: object, previousFilters: object, data: TableData[], filterOptions: FilterOption[]): TableData[] {
    const filterKeys = Object.keys(filters);
    const previousFilterKeys = Object.keys(previousFilters);
    const removedFilterKeys = previousFilterKeys.filter((filterKey) => !filterKeys.includes(filterKey));

    let newTableData = [...data];
    let reasonToRefetch = false;

    for (const filterKey of filterKeys) {
      const filterOption = filterOptions.find((option) => option.key === filterKey);
      const activeFilters = Object.values(filters[filterKey as keyof typeof filters]);
      const previousActiveFilters = Object.values(previousFilters[filterKey as keyof typeof previousFilters] ?? {});

      const filterLabels = activeFilters.map((value: any) => value.label);
      const filterValues = activeFilters.map((value: any) => value.value);

      if (filterOption?.dataType === 'array') {
        newTableData = newTableData.filter((item) => {
          return filterLabels.some((filterLabel) => {
            // @ts-ignore
            return item[filterKey].value.includes(filterLabel);
          });
        });
      } else if (filterOption?.dataType === 'ajax') {
        filterOption.query.params[filterKey] = filterValues;
        if (!reasonToRefetch) {
          reasonToRefetch = !isEqual(activeFilters, previousActiveFilters);
        }
      } else {
        newTableData = newTableData.filter((item) => {
          return filterLabels.some((filterLabel) => {
            return item[filterKey].value === filterLabel;
          });
        });
      }
    }

    for (const filterKey of removedFilterKeys) {
      const filterOption = filterOptions.find((option) => option.key === filterKey);

      if (filterOption?.dataType === 'ajax') {
        filterOption.query.params[filterKey] = [];
        reasonToRefetch = true;
      }
    }

    if (reasonToRefetch) {
      for (const filterKey of [...filterKeys, ...previousFilterKeys]) {
        const filterOption = filterOptions.find((option) => option.key === filterKey);

        if (filterOption?.dataType === 'ajax') {
          filterOption.query.invalidate();
          break;
        }
      }
    }

    return newTableData;
  },
  removeDuplicates(array: Value[]) {
    return array.filter((value, index, self) => self.findIndex(v => v.value === value.value) === index);
  },
  base64ToArrayBuffer(base64: string) {
    let binaryString = window.atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  },
  joinWithComma(...parts: (string | undefined)[]) {
    return parts.filter(Boolean).join(', ');
  },

  traceId(traceId: string) {
    return traceId ? `[Support code: ${traceId}]` : '';
  },
  downloadFile(url: string, filename: string): void {
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  },
  formatBits(bits: number): string {
    const oneMB = 1024 * 1024;
    if(bits < oneMB ) return `${(bits / 1024).toFixed(1)} kB`;
    return `${(bits / oneMB).toFixed(1)} MB`;
  }
};

export default Helpers;
