import {colors, fontFamily, fontSize, sizes} from 'patient-ping-remedy/packages/theme';
import styled from '@emotion/styled';

const StyledInput = styled.input`
  width: 100%;
  font-size: ${fontSize.small};
  font-family: inherit;
  border: none;
  height: ${sizes.large};
  border-top: 0.1rem solid ${colors.gray2};
  border-bottom: 0.1rem solid ${colors.gray2};
  margin: 0 ${sizes.xSmall};
    
  &:focus {
    outline: none;
  }
`;

const StyledInputDiv = styled.div((props: {width: string}) => ({
  width: props.width,
  minWidth: '24rem',
  fontSize: '1.5rem',
  fontFamily: `${fontFamily.default}`,
  padding: `0 ${sizes.xSmall}`,
  margin: `${sizes.small} 0 ${sizes.small} ${sizes.small}`,
  height: `${sizes.large}`,
  borderRadius: '8px',
  border: `0.1rem solid ${colors.gray2}`,
  background: colors.white,
  display: 'flex',
  alignItems: 'center',
}));

export {StyledInput, StyledInputDiv};
