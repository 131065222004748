import React, {PropsWithChildren, ReactNode, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
  FilterWrapper,
  ButtonWrapper,
  QuickFilterWrapper,
  SearchBarDiv,
  StyledTable,
  ThButton
} from "./SortableTable.styles";
import {SmallTypography, StandardTypography} from "patient-ping-remedy/packages/typography";
import Icon, {ICONS} from "patient-ping-remedy/packages/icon";
import {css} from "@emotion/css";
import Helpers from "../../helpers/helpers";
import SearchBarComponent from "./SearchBarComponent";
import {useRosterAwareState} from "../../hooks/useRosterAwareState";
import {debounce, isEqual} from "lodash";
import FilterComponent, {FilterOption, nonGroupedValues} from "./FilterComponent";
import Loading from "./Loading";
import Banner, {BannerTypes} from "patient-ping-remedy/packages/banner";
import DisplayHelpers from "../../helpers/display_helpers";
import QuickFilterBar, {QuickFilterInterface} from "./QuickFilterBar";
import {CarecoApiContext} from "../../app-context/careco-api-context";
import {
  MixpanelEventType, MixpanelTableSearchedEvent,
  MixpanelViewFilteredEvent, TableName, MixpanelSortEvent
} from "../../api/dto/mixpanel";
import {getMixpanelEventProperties} from "../../helpers/mixpanel_helpers";
import Chip from "patient-ping-remedy/packages/chip";

export const SORT_ORDER = {
  ASC: {
    value: 'asc',
    icon: ICONS['sort-up'],
  },
  DESC: {
    value: 'desc',
    icon:  ICONS['sort-down'],
  },
  NONE: {
    value: 'none',
    icon:  ICONS.sort,
  },
};

export type TableColumn = {
  tableHeader: ReactNode,
  key: string,
  type: "string" | "number" | "date" | "array" | "custom",
  value: string | string[] | number | number[],
  displayValue: React.ReactNode,
  columnWidth?: string,
};

export type TableData = {
  [key: string]: TableColumn;
};

export type CurrentSort = {
  tableColumn: TableColumn;
  sortOrder: typeof SORT_ORDER.ASC | typeof SORT_ORDER.DESC | typeof SORT_ORDER.NONE;
};

export type TableOverrides = {
  count?: number;
  searchFunction?: Function;
  filterFunction?: Function;
  searchTerm?: string;
  currentFiltersOverride?: object;
  initialFilters?: object;
  quickFilters?: QuickFilterInterface[];
  activeQuickFilter?: QuickFilterInterface | null;
}

type Props = PropsWithChildren & {
  id: string;
  loading: boolean,
  loadError: Error | null,
  data: TableData[];
  quickFilterOptions?: QuickFilterInterface[];
  header: ReactNode[];
  searchColumns: string[];
  sortableColumns: TableColumn[];
  searchPlaceholder: string;
  filterOptions?: FilterOption[];
  resetTableFilters?: boolean;
  setResetTableFilters?: Function;
  tableOverrides?: TableOverrides;
  tabName?: TableName;
}

const SortableTable = (props: Props) => {
  const [currentSort, setCurrentSort] = useRosterAwareState<CurrentSort | null>(null);
  const [searchTerm, setSearchTerm] = useRosterAwareState<string>('');
  const [initialFilters, setInitialFilters] = useState<object>({});
  const [currentFilters, setCurrentFilters] = useRosterAwareState<object>({});
  const [previousFilters, setPreviousFilters] = useRosterAwareState<object>({});
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [activeQuickFilter, setActiveQuickFilter] = useState<QuickFilterInterface | null>();
  const [quickFilters, setQuickFilters] = useState<QuickFilterInterface[]>([]);
  const {carecoApi} = useContext(CarecoApiContext);
  const searchEventSentRef = useRef(false);

  useEffect(() => {
    if (!Helpers.isEmpty(searchTerm) && !searchEventSentRef.current) {
      const searchEvent: MixpanelTableSearchedEvent = {
        ...getMixpanelEventProperties(MixpanelEventType.BIH_TABLE_SEARCHED),
        tableName: props.tabName!,
      };
      carecoApi?.postMixpanelEvent(searchEvent);
      searchEventSentRef.current = true;
    }
    if (Helpers.isEmpty(searchTerm)) {
      searchEventSentRef.current = false;
    }
  }, [searchTerm, props.tabName]);

  useEffect(() => {
    const filterKeys = Object.keys(currentFilters);
    if (filterKeys.length > 0) {
      const filterTabName = props.tabName!;
      const event: MixpanelViewFilteredEvent = {
        ...getMixpanelEventProperties(MixpanelEventType.BIH_VIEW_FILTERED),
        viewName: filterTabName,
        filterCriteria: filterKeys
      };
      carecoApi?.postMixpanelEvent(event);
    }
  }, [carecoApi, currentFilters, props.tabName!]);

  const setNewTableData = useCallback((newTableData: TableData[]) => {
    if(currentSort) {
      setTableData(Helpers.sort(newTableData,
        currentSort.tableColumn.type,
        currentSort.tableColumn.key,
        currentSort.sortOrder.value)
      );
    } else {
      setTableData(newTableData);
    }
  }, [currentSort]);

  useEffect(() => {
    setNewTableData(props.data);

    if(!Helpers.isEmptyObject(currentFilters)) {
      filterTable(currentFilters);
    } else if(props.tableOverrides?.currentFiltersOverride) {
      setCurrentFilters(props.tableOverrides.currentFiltersOverride);
      filterTable(props.tableOverrides.currentFiltersOverride);
    } else if(props.tableOverrides?.initialFilters) {
      // filter table again only if initial filters have changed
      if(!isEqual(initialFilters, props.tableOverrides.initialFilters)) {
        setInitialFilters(props.tableOverrides.initialFilters);
        setCurrentFilters(props.tableOverrides.initialFilters);
        filterTable(props.tableOverrides.initialFilters, true);
      }
    }

    setQuickFilters([
      ...props.quickFilterOptions ?? [],
      {
        displayText: `All (${props.tableOverrides?.count ?? props.data.length})`,
        key: 'all',
        value: null
      }
    ]);
  }, [props.data, props.quickFilterOptions, props.tableOverrides?.currentFiltersOverride]);

  useEffect(() => {
    // set quick filters that are applied based on filters
    let activeQuickFilter;
    if (Helpers.isEmptyObject(currentFilters)) {
      // no filters applied make all quick filter active
      activeQuickFilter = quickFilters.find((quickFilter: QuickFilterInterface) => quickFilter.key === 'all');
    } else {
      // one filter applied make that quick filter active if available
      for(const [key, value] of Object.entries(currentFilters)) {
        let filterOption = props.filterOptions!.find((option: FilterOption) => option.key === key);

        if(filterOption?.hasQuickFilter) {
          for(const filter of value) {
            if(filter.quickFilter) {
              activeQuickFilter = quickFilters.find((quickFilter: QuickFilterInterface) => {
                return quickFilter.value?.value === filter.value;
              });

              break;
            }
          }

          if (activeQuickFilter) {
            // break loop early since there can only be one active quick filter
            break;
          }
        }
      }
    }
    setActiveQuickFilter(activeQuickFilter);

  }, [tableData, currentFilters, props.loading]);

  const resetTableFilters = () => {
    setCurrentSort(null);
    setSearchTerm('');
    setCurrentFilters({});
    setPreviousFilters({});
    setActiveQuickFilter(null);
  };

  useEffect(() => {
    if(props.resetTableFilters) {
      resetTableFilters();
      props.setResetTableFilters!(false);
    }
  }, [props.resetTableFilters, props.setResetTableFilters]);

  const sortBy = (sortable: TableColumn,
    sortOrder: typeof SORT_ORDER.ASC | typeof SORT_ORDER.DESC | typeof SORT_ORDER.NONE) => {
    let newCurrentSort = {
      tableColumn: sortable,
      sortOrder: sortOrder === SORT_ORDER.ASC ? SORT_ORDER.DESC : SORT_ORDER.ASC
    };

    setCurrentSort(newCurrentSort);
    setTableData(Helpers.sort(tableData,
      newCurrentSort.tableColumn.type,
      newCurrentSort.tableColumn.key,
      newCurrentSort.sortOrder.value));
  };

  const search = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    setCurrentFilters({});
    setPreviousFilters({});
    setActiveQuickFilter(null);
    (props.tableOverrides?.searchFunction ?? searchFunction)(searchTerm);
  };

  const searchFunction = debounce((searchTerm: string) => {
    if(!Helpers.isEmpty(searchTerm)) {
      let searchedTableData = Helpers.search(searchTerm, props.data, props.searchColumns);
      setNewTableData(searchedTableData);
    } else {
      setNewTableData(props.data);
    }
  }, 500);

  const filter = (value: nonGroupedValues[], key: string) => {
    setSearchTerm('');
    (props.tableOverrides?.filterFunction ?? filterFunction)(value, key);
  };

  const getSetNewCurrentFilters = (value: nonGroupedValues[], key: string) => {
    let newFilters = {
      ...currentFilters,
      [key]: value
    };

    if(Helpers.isEmptyArray(value)) {
      delete newFilters[key as keyof typeof newFilters];
    }

    setCurrentFilters(newFilters);
    return newFilters;
  };

  const filterFunction = (value: nonGroupedValues[], key: string) => {
    filterTable(getSetNewCurrentFilters(value, key));
  };

  const filterTable = (filters: object, ignoreChange?: boolean) => {
    setNewTableData(Helpers.filter(filters, ignoreChange ? filters : previousFilters, props.data, props.filterOptions ?? []));
    setPreviousFilters(filters);
  };

  const quickFilterFunction = (quickFilter: QuickFilterInterface) => {
    setActiveQuickFilter(quickFilter);
    if(quickFilter.key === 'all') {
      filterTable({});
      resetTableFilters();
    } else {
      filter([quickFilter.value!], quickFilter.key);
    }
  };

  useEffect(() => {
    if (currentSort && props.tabName) {
      const mixpanelEvent: MixpanelSortEvent = {
        ...getMixpanelEventProperties(MixpanelEventType.BIH_TABLE_SORTED),
        tableName: props.tabName,
        sortCriteria: currentSort.tableColumn.tableHeader?.toString()!,
      };
      carecoApi?.postMixpanelEvent(mixpanelEvent);
    }
  }, [currentSort, props.tabName]);

  const getHeaders = (header: ReactNode[] | string[], sortColumns: TableColumn[]): React.ReactNode => {
    return (
      <tr>
        {header.map((item, index) => {
          let sortable = sortColumns.find((column: TableColumn) => column.tableHeader === item);
          let isCurrentSort = currentSort && currentSort?.tableColumn.tableHeader === item;

          if(sortable) {
            return (
              <ThButton key={index}
                onClick={() => sortBy(sortable!, isCurrentSort ? currentSort!.sortOrder : SORT_ORDER.NONE)}>
                <SmallTypography>
                  {item}
                  {
                    currentSort && isCurrentSort &&
                    <Icon iconClass={currentSort.sortOrder.icon}
                      className={css({ marginLeft: '5px', fontSize: '14px'})} />
                  }

                  {
                    (!currentSort || !isCurrentSort) &&
                    <Icon iconClass={ICONS.sort}
                      className={css({ marginLeft: '5px', fontSize: '14px'})} />
                  }
                </SmallTypography>
              </ThButton>
            );
          } else {
            return (
              <th key={index}>
                <SmallTypography>{item}</SmallTypography>
              </th>
            );
          }
        })}
      </tr>
    );
  };

  const getBody = (data: TableData[]): React.ReactNode => {
    return (
      <>
        {data.map((trItem: TableData, index) => (
          <tr key={index}>
            {Object.keys(trItem).map((objectKey: string, index) => (
              <td key={index} className={css({ width: trItem[objectKey].columnWidth})}>
                <StandardTypography>{trItem[objectKey].displayValue}</StandardTypography>
              </td>
            ))}
          </tr>
        ))}
      </>
    );
  };

  const getAppliedFilters = () => {
    const filtersApplied =  Object.values(currentFilters)?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.length;
    }, 0);

    if (filtersApplied > 6) {
      return <Chip onClick={() => quickFilterFunction(quickFilters[0])}>
        {filtersApplied} filters applied
      </Chip>;
    }

    return Object.entries(currentFilters).map(([key, value]) => {
      return value.map((filterValue: nonGroupedValues) =>
        <Chip onClick={() => filter(
          value.filter((option: nonGroupedValues) => option.id !== filterValue.id),
          key
        )}>
          {filterValue.label}
        </Chip>
      );
    });
  };

  return (
    <>
      <FilterWrapper>
        <QuickFilterWrapper>
          <QuickFilterBar
            quickFilters={quickFilters}
            activeFilter={activeQuickFilter}
            filterFunction={quickFilterFunction}
          />
          {getAppliedFilters()}
        </QuickFilterWrapper>
        <ButtonWrapper>
          {props.children}
        </ButtonWrapper>
      </FilterWrapper>

      <SearchBarDiv>
        {
          props.filterOptions &&
          <FilterComponent
            id={props.id}
            filterFunction={filter}
            filterArgs={{
              filterOptions: props.filterOptions,
              currentFilters: props.tableOverrides?.currentFiltersOverride ?? currentFilters,
            }}
          />
        }
        <SearchBarComponent
          id={props.id}
          searchIcon={true}
          searchTerm={props.tableOverrides?.searchTerm ?? searchTerm}
          searchFunction={search}
          searchPlaceholder={props.searchPlaceholder}
          class={['small']}
        />
      </SearchBarDiv>

      {props.loading && <Loading />}
      {props.loadError && <Banner type={BannerTypes.WARNING}>{DisplayHelpers.displayErrorMessage(props.loadError)}</Banner>}

      { !props.loading && !props.loadError && tableData && tableData.length > 0 &&
        <StyledTable id={props.id}>
          <thead>{getHeaders(props.header, props.sortableColumns)}</thead>
          <tbody>{getBody(tableData)}</tbody>
        </StyledTable>
      }

      { !props.loading && !props.loadError && tableData.length === 0 &&
        <StandardTypography>No data to display</StandardTypography>
      }
    </>
  );
};

export default SortableTable;
