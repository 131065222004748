import {BorderedContent, Content, Header, TopRegion, Wrapper} from "./NotificationsPage.styles";
import React, {useEffect} from "react";
import {useInView} from "react-intersection-observer";
import Loading from "../../common/Loading";
import NotificationList from "./NotificationList";
import {useRosterStore} from "../../../store/roster_store";
import {StandardTypography} from "patient-ping-remedy/packages/typography";
import Banner, {BannerTypes} from "patient-ping-remedy/packages/banner";
import DisplayHelpers from "../../../helpers/display_helpers";
import {useAuthStore} from "../../../store/auth_store";
import {useIsMounted} from "../../../hooks/useIsMounted";
import {useNotifications} from "../../../hooks/useNotifications";
import NotificationOnlyShowUnreadSwitch from "./NotificationOnlyShowUnreadSwitch";
import {useNotificationStore} from "../../../store/notification_store";

function NotificationsPage() {
  const {currentUser } = useAuthStore();
  const {currentRoster} = useRosterStore();
  const { notifications } = useNotificationStore();
  const currentRosterId = currentRoster?.id ?? 0;
  const isMounted = useIsMounted();

  const { isLoading, fetchNextPage, totalElements, markAllAsRead, isFetching, error } = useNotifications({
    currentRosterId,
    onlyShowUnreadNotifications: currentUser?.only_show_unread_notifications || false,
    enabled: !!currentRoster,
  });

  const {ref, inView} = useInView({
    rootMargin: '200px',
    skip: !isMounted,
  });

  useEffect(() => {
    if (inView) {
      if (notifications && notifications.length < totalElements) {
        fetchNextPage();
      }
    }
  }, [inView]);

  const handleMarkAllAsRead = () => {
    markAllAsRead();
  };

  return (
    <Wrapper>
      <TopRegion>
        <Header>
          Notifications
        </Header>
        {currentUser &&
            <NotificationOnlyShowUnreadSwitch currentFetchedUser={currentUser} />
        }
      </TopRegion>
      <Content>
        <BorderedContent>
          {!currentRoster && <StandardTypography> No group selected </StandardTypography>}
          {error && <Banner type={BannerTypes.WARNING}>{DisplayHelpers.displayErrorMessage(error)}</Banner>}
          { isLoading && <Loading />}
          { notifications && currentRoster &&
              <>
                <NotificationList
                  notifications={notifications}
                  handleMarkAllAsRead={handleMarkAllAsRead}
                />
                <span ref={ref} onClick={() => fetchNextPage()} />
              </>
          }
          { isFetching && !isLoading && <Loading /> }
        </BorderedContent>
      </Content>
    </Wrapper>
  );
}

export default NotificationsPage;
