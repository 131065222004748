import React, {useContext, useMemo} from "react";
import {router} from "./router";
import {RouterProvider} from "react-router-dom";
import {QueryClient} from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {PresenceType, useIdleTimer} from "react-idle-timer";
import {useAuth0} from "@auth0/auth0-react";
import {CarecoApiContext} from "./app-context/careco-api-context";
import SessionTimeoutWarning from "./components/SessionTimeoutWarning";
import useHandleLogout from "./hooks/useHandleLogout";
import useWalkMe from "./hooks/useWalkMe";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity, // TODO need to discuss best configs with the team
      retry: 0
    },
  },
}
);

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
);

function App() {
  const timeout = useMemo(() => 1000 * 60 * (Number(process.env.REACT_APP_INACTIVITY_TIMEOUT_MINUTE) || 30), []);
  const promptBeforeIdle = useMemo(() => 1000 * 60 * (Number(process.env.REACT_APP_INACTIVITY_TIMEOUT_WARNING_MINUTE) || 10), []);

  const { getAccessTokenSilently } = useAuth0();
  const { carecoApi } = useContext(CarecoApiContext);
  const [showDevtools, setShowDevtools] = React.useState(false);
  const [showTimeoutWarning, setShowTimeoutWarning] = React.useState<boolean>(false);
  const handleLogout = useHandleLogout();
  useWalkMe();

  const onPresenceChange = (presence: PresenceType) => {
    const isIdle = presence.type === 'idle';
    const isActive = presence.type === 'active' && !presence.prompted;
    const isPrompted = presence.type === 'active' && presence.prompted;

    if (isIdle) {
      setShowTimeoutWarning(false);
      handleLogout();
    } else if (isActive) {
      setShowTimeoutWarning(false);
    } else if (isPrompted) {
      setShowTimeoutWarning(true);
    }
  };

  const handleStillHere = async () => {
    // ignore cache to get new token since existing will expire soon
    // Auth0 will still require logins every 24 hours
    getAccessTokenSilently({cacheMode: "off"})
      .then((newToken) => {
        activate();
        carecoApi?.getAllTogglzFlags();
      })
      .catch((error) => {
        // need cross-site cookies enabled for this to work
        console.error('Refreshing access token blocked. Redirecting to login. ' +
          'Check that all cookies are allowed in browser.');
      });
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onPresenceChange,
    timeout,
    promptBeforeIdle,
    throttle: 500
  });

  React.useEffect(() => {
    // @ts-expect-error
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <>
      {
        showTimeoutWarning &&
        <SessionTimeoutWarning
          handleStillHere={handleStillHere}
          getRemainingTime={getRemainingTime}
        />
      }
      <RouterProvider router={router} />
      <div style={{ fontSize: '18px'}}>
        <ReactQueryDevtools initialIsOpen={false} />
        {showDevtools && ['local', 'dev', 'qa'].includes(process.env.REACT_APP_ENV || 'prod') && (
          <React.Suspense fallback={null}>
            <ReactQueryDevtoolsProduction />
          </React.Suspense>
        )}
      </div>
    </>
  );
}

export default App;
